"use client";

import React, { forwardRef, useRef } from "react";
import Image from "next/image";

import { cn } from "@/src/lib/utils";
import { AnimatedBeam } from "@/src/components/magicui/animated-beam";

const Circle = forwardRef<
  HTMLDivElement,
  { className?: string; children?: React.ReactNode }
>(({ className, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "z-10 flex size-12 items-center justify-center rounded-full border-2 bg-white p-1.5",
        className,
      )}
    >
      {children}
    </div>
  );
});

Circle.displayName = "Circle";

export function AnimatedBeamCrosslist() {
  const containerRef = useRef<HTMLDivElement>(null);
  const div1Ref = useRef<HTMLDivElement>(null);
  const div2Ref = useRef<HTMLDivElement>(null);
  const div3Ref = useRef<HTMLDivElement>(null);
  const div4Ref = useRef<HTMLDivElement>(null);
  const div5Ref = useRef<HTMLDivElement>(null);
  const div6Ref = useRef<HTMLDivElement>(null);
  const div7Ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className="relative flex h-[500px] w-full items-center justify-center overflow-hidden rounded-lg p-10"
      ref={containerRef}
    >
      <div className="flex size-full flex-col items-stretch justify-between gap-10">
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div1Ref}>
            <Image src="/circlesMercari.png" alt="Mercari" width={30} height={30} />
          </Circle>
          <Circle ref={div5Ref}>
            <Image src="/circlesAmazon.png" alt="Amazon" width={30} height={30} />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div2Ref}>
            <Image src="/circlesFacebook.png" alt="Facebook" width={50} height={50} />
          </Circle>
          <Circle ref={div4Ref} className="size-16">
            <Image src="/sellRazeMetallic.png" alt="Sell Raze Metallic" width={36} height={36} />
          </Circle>
          <Circle ref={div6Ref}>
            <Image src="/circlesDepop.png" alt="Depop" width={50} height={50} />
          </Circle>
        </div>
        <div className="flex flex-row items-center justify-between">
          <Circle ref={div3Ref}>
            <Image src="/circlesOfferup.png" alt="Offerup" width={50} height={50} />
          </Circle>
          <Circle ref={div7Ref}>
            <Image src="/circlesEbay.png" alt="Ebay" width={50} height={50} />
          </Circle>
        </div>
      </div>

      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div1Ref}
        curvature={75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div2Ref}
        
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div3Ref}
        curvature={-75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div5Ref}
        curvature={75}
        endYOffset={-10}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div6Ref}
        toRef={div4Ref}
      />
      <AnimatedBeam
        containerRef={containerRef}
        fromRef={div4Ref}
        toRef={div7Ref}
        curvature={-75}
        endYOffset={-10}
        
      />
    </div>
  );
}