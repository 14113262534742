import Link from "next/link";
import Image from "next/image";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/src/components/ui/accordion";

const faqs = [
  {
    question: "How do I get started with selling on SellRaze?",
    answer:
      "Download the SellRaze app from the Apple App Store or Google Play, enter your phone number, and follow the guided setup to list your first item for sale. Our user-friendly interface simplifies the listing process to make getting started fast and easy.",
  },
  {
    question: "Can I list items on SellRaze if I'm new to online selling?",
    answer:
      "Absolutely! SellRaze is designed for sellers of all experience levels. We offer step-by-step guidance and tips for creating effective listings that sell quickly and maximize your profit.",
  },
  {
    question: "What is the process for listing an item on SellRaze?",
    answer:
      "Snap a photo, fill in the details with our prompts, set a price, and publish. Our AI streamlines this process, making it intuitive and quick—your listing goes live in seconds.",
  },
  {
    question: "How does listing on multiple marketplaces work with SellRaze?",
    answer:
      "SellRaze seamlessly lists your items on a network of top-rated seller accounts, ensuring maximum visibility and swift sales. Experience fast, lucrative selling like never before.",
  },
  {
    question: "How much are the fees with SellRaze?",
    answer:
      "SellRaze offers competitive fees tailored to maximize your profits. We charge a minimal commission only when you make a sale, ensuring our interests are aligned with your success.",
  },
  {
    question: "How can I make my listings stand out?",
    answer:
      "Enhance your listings with high-quality photos and detailed descriptions. Our AI recommendations help optimize your listings for visibility and appeal, making them stand out to potential buyers.",
  },
  {
    question: "What if I'm too busy to manage my listings?",
    answer:
      "SellRaze's automated features streamline your sales. Schedule listings, use our AI to auto-fill details, and let our smart notifications keep you updated effortlessly, saving you time.",
  },
  {
    question: "How do I deal with shipping and packaging?",
    answer:
      "SellRaze simplifies shipping by offering prepaid labels and guiding you to affordable packaging solutions. Ship confidently with our streamlined process, designed for seller ease.",
  },
];

const FAQ = () => {
  return (
    <section id="faq-section" className="mx-auto max-w-[800px] flex-col px-[16px]">
      <div className="my-14 px-4 text-center font-sans text-[36px] md:text-[42px] lg:text-[52px] font-semibold leading-[1.1] text-neutral-950">
        Frequently asked questions
      </div>
      <Accordion type="single" collapsible className="w-full rounded-[21px] space-y-4 font-sans">
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            value={`item-${index}`}
            className={`px-6 py-3 bg-[#FAFAFA] rounded-3xl hover:opacity-70 transition-all duration-200`}
          >
            <AccordionTrigger className="text-left font-sans text-lg font-semibold text-neutral-950">
              {faq.question}
            </AccordionTrigger>
            <AccordionContent className="font-sans text-base font-normal leading-normal text-neutral-500">
              {faq.answer}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
      <div className="flex flex-row w-full justify-between py-8 px-6 items-center">
        <p className="font-semibold">Couldn&apos;t find something?</p>
        <Link href="mailto:info@sellraze.com" className="flex flex-row items-center px-4 py-2 align-center items-center rounded-xl cursor-pointer gap-[10px] hover:bg-neutral-100 transition duration-200">
          <p className="font-semibold text-sellRazeBlue">Message us</p>
          <Image
            draggable={false}
            className="cursor-pointer"
            src="/chevronGradient.svg"
            width="7"
            height="12"
            alt=""
            priority
          />
        </Link>
      </div>
    </section>
  );
};

export default FAQ;
