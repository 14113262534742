interface HowToVideoProps {
  video: string;
}

const HowToVideo: React.FC<HowToVideoProps> = ({ video }) => {
  return (
    // <div className="relative mx-auto overflow-hidden rounded-[30px] border-b-[5px] border-l-[5px] border-r-[5px] border-t-[5px] border-black bg-black sm:rounded-[40px] lg:rounded-[40px] lg:border-b-[10px] lg:border-l-[10px] lg:border-r-[10px] lg:border-t-[10px] 2xl:rounded-[60px]">
    <div className="md:px-0 md:h-[377px] mx-auto my-auto w-[606px] select-none px-8 lg:block">
      <video
        autoPlay
        loop
        muted
        playsInline // This attribute is important for autoplay on iOS devices
        className="h-full w-full rounded-[28px] object-cover"
      >
        <source src={`/video/${video}.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    // </div>
  );
};

const HowItWorks = () => {
  return (
    <div className="container mx-auto max-w-7xl px-8 py-24 lg:px-8 xl:px-12">
      <div className="mb-6 flex flex-col items-center justify-between gap-8 pt-10 lg:flex-row lg:gap-16">
        <div className="w-full lg:w-1/2">
          <section className="flex flex-col items-start justify-between">
            <h2 className="md:text-5xl mb-12 font-sans text-4xl font-bold text-[#111111] lg:text-6xl">How it works</h2>
            {[1, 2, 3].map((step, index) => (
              <div key={step} className={`mb-12 ${index !== 0 ? "mt-12" : ""}`}>
                <div className="mb-6 flex aspect-square h-[50px] w-[50px] items-center justify-center rounded-full bg-white drop-shadow-2xl">
                  <span className="bg-clip-text font-sans font-bold text-blue-600">{step}</span>
                </div>
                <h3 className="md:text-3xl mb-4 font-sans text-2xl font-bold text-neutral-950">
                  {step === 1 && "Snap a picture or scan a barcode."}
                  {step === 2 && "Describe your item's condition."}
                  {step === 3 && "List, then ship."}
                </h3>
                <p className="md:text-xl max-w-[446px] font-sans text-lg font-[510] text-neutral-950 text-opacity-70">
                  {step === 1 && "We'll autofill the listing description, title, and category."}
                  {step === 2 && "We'll calculate the market value of your item."}
                  {step === 3 && "Tap to toggle listings on all of your favorite marketplaces instantly."}
                </p>
              </div>
            ))}
          </section>
        </div>
        <div className="flex w-full items-center justify-center lg:w-1/2">
          <HowToVideo video="SellRazeExplainer" />
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
