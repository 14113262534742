"use client";

import { useState, useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";
import { cn } from "@/src/lib/utils";

export default function NumberTicker({
  value,
  startValue,
  direction = "up",
  delay = 0,
  className,
}: {
  value: number;
  startValue: number;
  direction?: "up" | "down";
  className?: string;
  delay?: number; // delay in s
}) {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(startValue);
  const springValue = useSpring(motionValue, {
    damping: 80,
    stiffness: 300,
  });
  const isInView = useInView(ref, { once: true, margin: "0px" });
  const [displayValue, setDisplayValue] = useState(startValue.toFixed(2));

  useEffect(() => {
    if (isInView) {
      setTimeout(() => {
        motionValue.set(value);
      }, delay * 1000);
    }
  }, [motionValue, isInView, delay, value, direction]);

  useEffect(() => {
    const unsubscribe = springValue.on("change", (latest) => {
      setDisplayValue(latest.toFixed(2));
    });

    return () => unsubscribe();
  }, [springValue]);

  return (
    <span className={cn("inline-flex font-sfpro font-bold", className)}>
      <span
        className="font-bold inline-block text-black dark:text-white tracking-tight"
        ref={ref}
      >
        {displayValue}
      </span>
    </span>
  );
}
