import Image from "next/image";
import Link from "next/link";
import Marquee from "react-fast-marquee";
interface HowToVideoProps {
  video: string;
}

const HowToVideo: React.FC<HowToVideoProps> = ({ video }) => {
  return (
    // <div className="relative mx-auto overflow-hidden rounded-[30px] border-b-[5px] border-l-[5px] border-r-[5px] border-t-[5px] border-black bg-black sm:rounded-[40px] lg:rounded-[40px] lg:border-b-[10px] lg:border-l-[10px] lg:border-r-[10px] lg:border-t-[10px] 2xl:rounded-[60px]">
    <div className="md:px-0 md:h-[377px] mx-auto my-auto w-[606px] select-none px-8 lg:block">
      <video
        autoPlay
        loop
        muted
        playsInline // This attribute is important for autoplay on iOS devices
        className="h-full w-full rounded-[28px] object-cover"
      >
        <source src={`/video/${video}.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
    // </div>
  );
};


const PlatformsMarquee = () => {
  return (
        <>
            <div className="mx-auto mb-1 flex w-full justify-center text-center font-sfmono">
            POWERING THOUSANDS OF SELLERS ON
            </div>
            <section className="mx-auto mb-6 mt-0 max-w-screen-lg opacity-65">
            <Marquee gradient gradientWidth={25} className="h-[75px]" speed={45} autoFill loop={0}>
                <Image
                draggable={false}
                src="/integrations/amazon.svg"
                className="mx-4 h-[24px] w-[78px]"
                priority
                width="1000"
                height="1000"
                alt="amazon"
                />
                <Image
                draggable={false}
                src="/integrations/poshmark.svg"
                className="mx-4 h-[24px] w-[138px]"
                width="1000"
                height="1000"
                alt="poshmark"
                priority
                />
                <Image
                draggable={false}
                src="/integrations/offerUp.png"
                className="mx-4 h-[24px] w-[103px]"
                width="1000"
                height="1000"
                alt="grailed"
                priority
                />
                <Image
                draggable={false}
                src="/integrations/stockX.png"
                className="mx-4 h-[24px] w-[120px]"
                width="1000"
                height="1000"
                alt="vinted"
                priority
                />
                <Image
                draggable={false}
                src="/integrations/depop.svg"
                className="mx-4 h-[24px] w-[95px]"
                width="1000"
                height="1000"
                alt="depop"
                priority
                />
                <Image
                draggable={false}
                src="/integrations/ebay.svg"
                className="mx-4 h-[24px] w-[69px]"
                width="1000"
                height="1000"
                alt="ebay"
                priority
                />
                <Image
                draggable={false}
                src="/integrations/mercari.svg"
                className="mx-4 h-[24px] w-[119px]"
                width="1000"
                height="1000"
                alt="mercari"
                priority
                />
                <Image
                draggable={false}
                src="/integrations/facebook.svg"
                className="mx-4 h-[24px] w-[100px]"
                width="1000"
                height="1000"
                alt="facebook"
                priority
                />
            </Marquee>
            </section>
        </>
  );
};

export default PlatformsMarquee;
