"use client";

import Footer from "./footer";
import Header from "./header";
import "./styles.scss";
// import posthog from "posthog-js";
import AutoplayVideo from "./home/autoplayVideo";

import { CTARow } from "./ctaRow";

import {
  FAQ,
  Testimonials,
  BentoMobile,
  AllInOne,
  Hero,
  MissionControl,
  FeatureBreakdown,
} from "./home";

// posthog.init("phc_XbaVMJxYBOdYd6i6Ti3AOvAXQpLSLE1cQtvWi7hodfC", { api_host: "https://app.posthog.com" });

const EmailCaptureFallback = () => {
  return (
    <>
      <div className="[ email_capture disable-flow ]">
        <input
          className="[ input email_input ss-large ] [ text-16 round ]"
          type="text"
          maxLength={80}
          required={true}
          placeholder={"Enter your email address"}
          //disabled={i.disabled}
        />
        <button
          className="[ button width-50 email_button width-40 ] [ text-16 ]"
          data-variant="gradient"
          data-size="thin"
        >
          Start for Free
        </button>
      </div>
    </>
  );
};

const Landing = () => {

  return (
    <div className="overflow-x-none">

      <main className="[ xpadding nosel ] margin-top">
        <Hero />
        <AllInOne />
        <MissionControl />
        {/* <HowItWorks/> */}
        <BentoMobile />
        <FeatureBreakdown />
        <FAQ />
        <Testimonials />
        <CTARow />
      </main>
      <Footer />
    </div>
  );
};

export default Landing;

