import Image from "next/image";
import Link from "next/link";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/src/components/ui/carousel";
const reviews = [
  {
    name: "Braden",
    position: "New to selling online",
    content: (
      <div>
        {`"Before I found SellRaze I had never sold an item online. I was able to create my first listing in 2
        minutes and my item sold almost immediatly. This is a game changer"`}
      </div>
    ),
    img: "/customers/braden.png",
  },
  {
    name: "Carter",
    position: "Regular reseller",
    content: (
      <div>
        {`"I have already made enough sales on eBay to cover the cost of SellRaze + made another $50 and it's only been a few days.
        Well worth the download. So much to love about the product!"`}
      </div>
    ),
    img: "/customers/carter.png",
  },
  {
    name: "Leo",
    position: "Full-time eBay seller",
    content: (
      <div>
        {`"As a reseller on multiple platforms, SellRaze has transformed my business. It allows me to identify, price, and crosslist in a few seconds!
        Listing used to take me hours. SellRaze saved me so much time and effort. I love it!"`}
      </div>
    ),
    img: "/customers/leo.png",
  },
  {
    name: "@AndySellsOnline",
    position: "Casual Seller",
    content: (
      <div>
        {`"I've sold my clothes on Poshmark, Mercari and Depop for years. I have now expanded my operations to several new marketplaces 
        with no additional effort. The speed with support is also incredible! Love the SellRaze team."`}
      </div>
    ),
    img: "/customers/miki.png",
  },
  {
    name: "Sean",
    position: "College Student",
    content: (
      <div>
        {`"I've never even thought about selling online, being in school has made it impossible. Now I want to
        sell every day, sellraze makes it so easy to make money, its the best app I've ever used"`}
      </div>
    ),
    img: "/customers/sean.png",
  },
  {
    name: "Tyler",
    position: "Full time seller",
    content: (
      <div>
        {`"Wow. I've sold on every marketplace you can imagine. SellRaze has boosted my business by 130% in the
        last month so I can focus on sourcing inventory and not listing."`}
      </div>
    ),
    img: "/customers/tyler.png",
  },
  {
    name: "Vik",
    position: "Full-time Student",
    content: (
      <div>
        {`"Between classes and homework, I've never had time to sell my old clothes. Now that I have SellRaze I
        cleaned out my entire closet and made $234 in 4 days. Must have for college students"`}
      </div>
    ),
    img: "/customers/vik.png",
  },
  {
    name: "Jeff",
    position: "Full Time eBay Seller",
    content: (
      <div>
        {`"I source my inventory from pallets & garage sales. SellRaze allows me to list my items 10x faster than before. It's boosted my business's revenue by 34% in the last month."`}
      </div>
    ),
    img: "/customers/jeff.png",
  },
];

const Testimonials = () => {
  return (
    <section className="mx-2 w-full items-center py-24 space-y-16">
      <div className="flex flex-col lg:flex-row px-12 md:px-36 lg:px-52 align-center items-center justify-between mb-24 lg:mb-[2rem] ">
        <div className="text-center lg:text-start font-sans text-[36px] md:text-[42px] lg:text-[52px] font-bold text-neutral-950 leading-[120%]">
          Sellers using SellRaze
          <div className="font-semibold text-[18px] md:text-[21px] lg:text-2xl">See what successful sellers are saying.</div>
        </div>
        <div
          className="p-3 align-center items-center rounded-xl cursor-pointer gap-[10px] hover:bg-neutral-100 transition duration-200"
        >
          <Image src="/twitter.svg" alt="" height="16" width="16" />
          <Link
            className="cursor-pointer bg-sellRazeBlue bg-clip-text font-sans font-semibold text-transparent"
            href="https://x.com/sellraze"
            target="_blank" 
            rel="noopener noreferrer"
          >
            Follow SellRaze
          </Link>
          <Image
            draggable={false}
            className="cursor-pointer"
            src="/chevronGradient.svg"
            width="7"
            height="12"
            alt=""
            onClick={() => {
              window.location.href =
                "https://docs.google.com/forms/d/e/1FAIpQLSc6iul3h6ak4PsqEiRg0yK9jqk2BWtueh0Bz8V9zQSR_rgdGA/viewform?usp=sf_link";
            }}
            priority
          />
        </div>
      </div>
      <Carousel
        opts={{
          align: "start",
          loop: true,
        }}
        className="svg-fix max-w-screen mx-auto w-full select-none flex-row"
      >
        <CarouselContent className="-ml-2">
          {reviews.map((rev, index) => (
            <div key={index} className="pl-4">
              <div className="p-1">
                <div className="inline-flex h-[270px] w-[340px] flex-col items-start justify-start rounded-[22px] border bg-neutral-100 p-6">
                  <div className="flex h-full flex-col items-start justify-between gap-2.5">
                    <div className="font-sans text-[18px] text-base leading-normal text-[#080C10] text-opacity-100">
                      {rev.content}
                    </div>
                    <div className="flex flex-row w-full justify-between items-center">
                      <div>
                        <div className="font-sans text-md font-semibold text-neutral-950">{rev.name}</div>
                        <div className="font-sans text-[14px] font-medium text-neutral-500">{rev.position}</div>
                      </div>
                      <Image
                        draggable={false}
                        alt="User Image"
                        className="h-[42px] w-[42px] rounded-[49px]"
                        height={42}
                        width={42}
                        src={rev.img}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </CarouselContent>
        <CarouselPrevious className="mr-10" />
        <CarouselNext className="mr-10" />
      </Carousel>
    </section>
  );
};

export default Testimonials;
