import React from "react";
import { cn } from "@/src/lib/utils";

export interface OrbitingCirclesProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  reverse?: boolean;
  duration?: number;
  delay?: number;
  radius?: number;
  path?: boolean;
}

export default function OrbitingCircles({
  className,
  children,
  reverse,
  duration = 20,
  delay = 10,
  radius = 50,
  path = true,
}: OrbitingCirclesProps) {
  const renderCircle = (child: React.ReactNode, index: number) => (
    <div
      key={index}
      style={
        {
          "--duration": duration,
          "--radius": radius,
          "--delay": -(delay + (index * duration) / React.Children.count(children)),
        } as React.CSSProperties
      }
      className={cn(
        "absolute flex size-full transform-gpu animate-orbit items-center justify-center rounded-full border bg-black/10 [animation-delay:calc(var(--delay)*1000ms)] dark:bg-white/10",
        { "[animation-direction:reverse]": reverse },
        className,
      )}
    >
      {child}
    </div>
  );

  return (
    <>
      {path && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          className="pointer-events-none absolute inset-0 size-full"
        >
          <circle
            className="stroke-black/10 stroke-1 dark:stroke-white/10"
            cx="50%"
            cy="50%"
            r={radius}
            fill="none"
          />
        </svg>
      )}
      {React.Children.map(children, renderCircle)}
    </>
  );
}
