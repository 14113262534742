"use client";
import Image from "next/image";
import { cn } from "@/src/lib/utils";
import { AnimatedList } from "@/src/components/magicui/animated-list";

interface Item {
  name: string;
  message: string;
  icon: string;
  color: string;
  time: string;
}

let notifications = [
  {
    name: "Payment received",
    message: "Sony WH-1000XM4 has sold.",
    time: "8m ago",
    icon: "/appIconEbay.png",
    color: "#FFFFFF",
  },
  {
    name: "New message",
    message: "Is this available?",
    time: "1h ago",
    icon: "/appIconFacebook.png",
    color: "#3B5998",
  },
  {
    name: "New message",
    message: "Would you be willing to do $350?",
    time: "5m ago",
    icon: "/appIconDepop.png",
    color: "#E20020",
  },
  {
    name: "Payment received",
    message: "Nike Dunk Low UNC (2021) has sold.",
    time: "3h ago",
    icon: "/appIconAmazon.png",
    color: "#F47025",
  },
  {
    name: "New event",
    message: "Can I counter at $1200?",
    time: "2m ago",
    icon: "/appIconOfferup.png",
    color: "#0E5B5B",
  },
];

notifications = Array.from({ length: 10 }, () => notifications).flat();

const Notification = ({ name, message, icon, color, time }: Item) => {
  return (
    <figure
      className={cn(
        "relative mx-auto min-h-fit w-full max-w-[400px] cursor-pointer overflow-hidden rounded-2xl p-4",
        // animation styles
        "transition-all duration-200 ease-in-out hover:scale-[103%]",
        // light styles
        "bg-white",
        // dark styles
        "transform-gpu dark:bg-transparent dark:backdrop-blur-md dark:[border:1px_solid_rgba(255,255,255,.1)]",
      )}
    >
      <div className="flex flex-row items-center gap-1">
        <div
          className="flex w-10 h-10 items-center justify-center rounded-2xl flex-shrink-0"
          style={{
            backgroundColor: color,
          }}
        >
          <Image src={icon} alt={name} width={1000} height={1000} className="w-10 h-10 object-contain rounded-2xl" />

        </div>
        <div className="flex flex-col overflow-hidden text-start">
          <figcaption className="align-center flex flex-row items-center whitespace-pre text-lg font-medium dark:text-white">
            <span className="text-xs">{name}</span>
            <span className="mx-1 mb-1">·</span>
            <span className="text-xs text-gray-500">{time}</span>
          </figcaption>
          <p className="text-sm font-semibold text-gray-700">
            {message}
          </p>
        </div>
      </div>
    </figure>
  );
};

export function AnimatedListNegotiate({
  className,
}: {
  className?: string;
}) {
  return (
    <div
      className={cn(
        "relative flex h-[500px] w-full flex-col p-8 overflow-hidden rounded-lg",
        className,
      )}
    >
      <AnimatedList className="gap-0">
        {notifications.map((item, idx) => (
          <Notification {...item} key={idx} />
        ))}
      </AnimatedList>
    </div>
  );
}
