import Image from "next/image";
import Link from "next/link";
import AutoplayVideo from "./autoplayVideo";
import { Button } from "@/src/components/ui/button";
import GridPattern from "@/src/components/magicui/grid-pattern";
import { cn } from "@/src/lib/utils";
import Header from "../header";
import PlatformsMarquee from "./platformsMarquee";

const Hero = () => {
  return (
    <>
      <Header />
      <div className="relative mx-auto max-w-7xl px-8 lg:px-12 pb-20">
        <GridPattern
          width={70}
          height={70}
          x={-1}
          y={-1}
          strokeDasharray={"4 2"}
          strokeWidth={1}
          className={cn("absolute inset-0 z-20 [mask-image:radial-gradient(750px_circle_at_center,white,transparent)]")}
        />
        <div className="relative z-10">
          <div className="flex flex-col items-center text-center lg:text-start gap-8 lg:flex-row lg:gap-16">
            <div className="mt-8 w-full max-w-[487px] font-sans lg:mt-0 lg:w-1/2 space-y-6">
              <div className="inline-flex space-x-3 items-center">
                <Link href={"https://apps.apple.com/us/app/sellraze-list-sell-earn/id6455042085"}>
                  <div className={"mx-auto inline-flex"}>
                    <div className="inline-flex items-center space-x-2 rounded-full border-b-[1px] border-l-[1px] border-r-[1px] border-t-[1px] border-[#076AFF] bg-blue-50 px-2 lg:px-3 py-1.5 text-center font-sans text-xs text-blue-600">
                      <Image src="/star.png" width={100} height={100} alt="Star" className="w-[14px] h-[14px] hidden lg:block" />
                      <div className="text-[10px] lg:text-sm md:tracking-wide font-sfmono font-medium tracking-tighter italic">4.9 on the App Store</div>
                      <Image alt="" width={8} height={8} src="./arrowupright.svg" className="my-auto aspect-square w-2" />
                    </div>
                  </div>
                </Link>
                <span className="text-[10px] lg:text-sm font-sans font-bold"> Trusted by 5K+ users.</span>
              </div>

              <div className="flex w-full flex-col bg-clip-text text-5xl leading-[120%] text-black lg:text-7xl lg:leading-[72px]">
                <div className="">
                  <span className="font-sans font-bold text-[#111111]">Sell your items</span>
                  <div className="flex-row">
                    <span className="font-sans font-bold text-[#111111]"> in</span>
                    <span className="font-sans font-bold text-sellRazeBlue"> seconds.</span>
                  </div>
                </div>
              </div>

              <div className="w-full text-base text-neutral-950 text-opacity-70">
                <span className="font-sfPro text-md lg:text-xl font-medium leading-normal text-neutral-950 lg:text-[24px]">
                  Make money without the headaches of selling.{" "}
                </span>
                <span className="font-sfPro text-md lg:text-xl font-medium leading-normal lg:text-[24px]">
                  List across your favorite marketplaces with a tap.
                </span>
              </div>
              <div className="flex flex-row w-full space-x-4 px-2 lg:px-0">
                <Button size="lg" variant="default" href="/dashboard">
                  <span className="font-semibold">Start for free</span>
                </Button>
                <Button
                  size="lg"
                  variant="secondary"
                  href="mailto:info@sellraze.com"
                  className="shadow-[0_4px_4px_rgba(0,0,0,0.08)]"
                >
                  <span className="font-semibold">Talk to sales</span>
                </Button>
              </div>
            </div>
            <div className="flex w-full justify-center lg:w-1/2 lg:justify-end">
              <AutoplayVideo video="landingPageAnimation" size={625} />
            </div>
          </div>
        </div>
        <PlatformsMarquee />
      </div>
    </>
  );
};

export default Hero;
